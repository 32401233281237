var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.createAndUpdateSettings)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/favorites/setting/create"}},[_vm._v("追加")]):_vm._e()],1),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"loading":_vm.setting.fetchLoading,"items":_vm.setting.items,"items-per-page":-1,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.favoriteItemStatusName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-underline",attrs:{"to":("/favorites/setting/" + (item.favoriteItemStatusId))}},[_vm._v(" "+_vm._s(item.favoriteItemStatusName)+" ")])]}},{key:"item.intervalCount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.intervalCount)+"ヶ月〜")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.myRoleSettings.deleteSettings)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","loading":_vm.isSaving},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1)],_c('v-btn',{staticClass:"mt-5",attrs:{"elevation":"0","color":"primary","to":"/favorites","disabled":_vm.isSaving}},[_vm._v("戻る")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }