import {
  getRequestFavorites,
  getRequestFavorite,
  getFavoriteSetting,
  createRequestFavorite,
  saveFavoriteSetting,
  FavoriteItem,
  FavoriteItemProps,
  FavoriteStatus,
  FavoriteStatusItem,
  saveRequestFavorite,
  createFavoriteSetting,
} from '@/admin/favorites';
import { DisplayDate } from '@/admin/util';
import { computed, reactive, ref, Ref } from '@vue/composition-api';
import { nanoid } from 'nanoid';

interface FavoriteProperty {
  favoriteItemName: string;
  color: string;
  defaultImage: string;
  imageSettings: {
    image: string;
    displayStartDate: DisplayDate;
    displayEndDate: DisplayDate;
  }[];
  accessStartDate?: DisplayDate;
  accessEndDate?: DisplayDate;
  deleteDate?: DisplayDate;
}

export interface ImageSetting {
  image: string;
  displayStartDate: DisplayDate;
  displayEndDate: DisplayDate;
}

export interface ImageSettingProps {
  image: string;
  displayStartDate: number;
  displayEndDate?: number;
}

export class FavoriteSetting {
  _fetchLoading = ref(false);
  _items: Ref<FavoriteStatus[]> = ref([]);
  _itemOptions = computed(() =>
    this._items.value.map((item) => ({
      text: item.favoriteItemStatusName,
      value: item.favoriteItemStatusId,
    }))
  );
  _item: Ref<FavoriteStatus> = ref({
    favoriteItemStatusId: nanoid(),
    favoriteItemStatusName: '',
    intervalCount: 0,
    textColor: '',
    nameBackGroundColor: '',
    description: '',
    linkUrl: '',
    image: '',
  });
  private _favoriteItemStatusId = ref('');
  private props: {
    statuses: FavoriteStatus[];
  } | null = null;

  get fetchLoading() {
    return this._fetchLoading.value;
  }
  get favoriteItemStatusId() {
    return this._favoriteItemStatusId.value;
  }
  get items() {
    return this._items.value;
  }
  get itemOptions() {
    return this._itemOptions.value;
  }
  get item() {
    return this._item.value;
  }

  constructor(favoriteItemStatusId?: string) {
    if (favoriteItemStatusId) this._favoriteItemStatusId.value = favoriteItemStatusId;
    this.init();
  }

  private _getSettingsFavorite = async () => {
    try {
      const favorites = await getFavoriteSetting();
      return favorites as FavoriteStatusItem;
    } catch (e) {
      return {} as FavoriteStatusItem;
    }
  };

  init = async () => {
    this._fetchLoading.value = true;
    const _settings = await this._getSettingsFavorite();
    if (_settings) {
      const statuses = _settings.value.statuses;
      this._items.value = statuses.sort((a, b) => (a.intervalCount < b.intervalCount ? -1 : 1));
      if (this._favoriteItemStatusId.value) {
        this._item.value = statuses.find(
          (status) => status.favoriteItemStatusId === this._favoriteItemStatusId.value
        ) as FavoriteStatus;
      }
    } else {
      const props: FavoriteStatusItem = {
        settingId: 'favoriteItemStatus',
        value: {
          statuses: [],
        },
      };
      await createFavoriteSetting(props);
      return props;
    }
    this._fetchLoading.value = false;
  };

  saveFavoriteSetting = async () => {
    this.creteProps();
    if (!this.props) return;
    await saveFavoriteSetting(this.props);
  };

  createFavoriteSetting = async () => {
    this.creteProps();
    if (!this.props) return;
    this.props.statuses.push(this._item.value);
    await saveFavoriteSetting(this.props);
  };

  removeFavoriteSetting = async (item: FavoriteStatus) => {
    const index = this._items.value.indexOf(item);
    this._items.value.splice(index, 1);
    const props = {
      statuses: this._items.value,
    };
    await saveFavoriteSetting(props);
  };

  creteProps = () => {
    if (
      !this._item.value.favoriteItemStatusName ||
      !this._item.value.textColor ||
      !this._item.value.nameBackGroundColor ||
      !this._item.value.description
    ) {
      throw '未入力があります';
    } else if (
      this.item.linkUrl &&
      !this.item.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)
    ) {
      throw 'URLが不正です。ドメインから正しく指定してください';
    } else if (
      !this._favoriteItemStatusId.value &&
      this._items.value.find((item) => item.intervalCount === this._item.value.intervalCount)
    ) {
      throw '重複した推し期間が存在します';
    } else {
      this.props = {
        statuses: this._items.value,
      };
    }
  };
}

export class Favorites {
  fetchLoading = false;
  items: FavoriteItem[] = [];
  // 推しの名前が入っている配列
  favoriteNames: { text: string; value: string }[] = [];

  constructor() {
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    const _items = await this.getFavorites();
    this.items = _items.sort((a, b) => (a.createDate < b.createDate ? -1 : 1));
    this.favoriteNames = this.items.map((item) => {
      return {
        text: item.favoriteItemName,
        value: item.favoriteItemId,
      };
    });
    this.fetchLoading = false;
  };

  getFavorites = async () => {
    try {
      const favorites = await getRequestFavorites();
      return favorites as FavoriteItem[];
    } catch (e) {
      return [] as FavoriteItem[];
    }
  };
}

export class Favorite {
  _fetchLoading = ref(false);
  _isActiveDialog = ref(false);
  _item: Ref<FavoriteProperty> = ref({
    favoriteItemName: '',
    color: '',
    defaultImage: '',
    imageSettings: [],
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    deleteDate: new DisplayDate(),
  });
  _imageSetting: Ref<ImageSetting> = ref({
    image: '',
    displayStartDate: new DisplayDate(),
    displayEndDate: new DisplayDate(),
  });
  _editImageSettingItem: Ref<ImageSetting | null> = ref(null);
  _isImageSettingLastEndDate = ref(true);
  private props: FavoriteItemProps | null = null;
  private favoriteItemId = '';

  get fetchLoading() {
    return this._fetchLoading.value;
  }
  get isActiveDialog() {
    return this._isActiveDialog.value;
  }
  set isActiveDialog(isActiveDialog: boolean) {
    this._isActiveDialog.value = isActiveDialog;
  }
  get isImageSettingLastEndDate() {
    return this._isImageSettingLastEndDate.value;
  }
  set isImageSettingLastEndDate(isImageSettingLastEndDate: boolean) {
    this._isImageSettingLastEndDate.value = isImageSettingLastEndDate;
  }
  get imageSetting() {
    return this._imageSetting.value;
  }
  set imageSetting(imageSetting: ImageSetting) {
    this._imageSetting.value = imageSetting;
  }
  get editImageSettingItem() {
    return this._editImageSettingItem.value;
  }
  set editImageSettingItem(editImageSettingItem: ImageSetting | null) {
    this._editImageSettingItem.value = editImageSettingItem;
  }
  get item() {
    return this._item.value;
  }

  constructor(favoriteItemId?: string) {
    if (favoriteItemId) {
      this.favoriteItemId = favoriteItemId;
    }
    this.init();
  }

  init = async () => {
    this._fetchLoading.value = true;
    this._item.value = await this.getFavorite();
    this._fetchLoading.value = false;
  };

  private _getFavorite = async (favoriteItemId: string) => {
    try {
      const favorite = await getRequestFavorite(favoriteItemId);
      return favorite as FavoriteItem;
    } catch (e) {
      return {} as FavoriteItem;
    }
  };

  getFavorite = async () => {
    if (!this.favoriteItemId) return this.item;
    const _favorite = await this._getFavorite(this.favoriteItemId);
    const favorite: FavoriteProperty = {
      favoriteItemName: _favorite.favoriteItemName,
      color: _favorite.color,
      defaultImage: _favorite.defaultImage,
      imageSettings: [],
    };
    if (_favorite.imageSettings?.length) {
      if (_favorite.imageSettings.slice(-1)[0]) {
        this._isImageSettingLastEndDate.value = !!_favorite.imageSettings.slice(-1)[0].displayEndDate;
      }
      favorite.imageSettings = _favorite.imageSettings.map((item) => {
        return {
          image: item.image,
          displayStartDate: new DisplayDate(item.displayStartDate),
          displayEndDate: new DisplayDate(item.displayEndDate),
        };
      });
    }
    if (_favorite.accessStartDate) favorite.accessStartDate = new DisplayDate(_favorite.accessStartDate);
    if (_favorite.accessEndDate) favorite.accessEndDate = new DisplayDate(_favorite.accessEndDate);
    if (_favorite.deleteDate) favorite.deleteDate = new DisplayDate(_favorite.deleteDate);
    return favorite;
  };

  createProps = () => {
    if (!this.item.favoriteItemName || !this.item.defaultImage || !this.item.color) {
      throw '未入力があります';
    } else if (
      this.item.accessStartDate?.value &&
      this.item.accessEndDate?.value &&
      this.item.accessEndDate.value <= this.item.accessStartDate.value
    ) {
      throw '選択終了日時が開始日より前に設定されています';
    } else if (
      this.item.accessStartDate?.value &&
      this.item.deleteDate?.value &&
      this.item.deleteDate.value <= this.item.accessStartDate.value
    ) {
      throw '表示終了日時が選択開始日より前に設定されています';
    } else if (
      this.item.accessEndDate?.value &&
      this.item.deleteDate?.value &&
      this.item.deleteDate.value <= this.item.accessEndDate.value
    ) {
      throw '表示終了日時が選択終了日より前に設定されています';
    } else {
      this.props = {
        favoriteItemName: this.item.favoriteItemName,
        defaultImage: this.item.defaultImage,
        color: this.item.color,
      };
      if (this.item.imageSettings.length) {
        this.props.imageSettings = this.item.imageSettings.map((imageSetting) => {
          const _imageSetting: ImageSettingProps = {
            image: imageSetting.image,
            displayStartDate: imageSetting.displayStartDate.value ? imageSetting.displayStartDate.value : Date.now(),
          };
          if (imageSetting.displayEndDate.value) {
            _imageSetting.displayEndDate = imageSetting.displayEndDate.value;
          }
          return _imageSetting;
        });
      }
      if (this.item.accessStartDate) this.props.accessStartDate = this.item.accessStartDate.value;
      if (this.item.accessEndDate) this.props.accessEndDate = this.item.accessEndDate.value;
      if (this.item.deleteDate) this.props.deleteDate = this.item.deleteDate.value;
    }
  };

  createFavorite = async () => {
    this.createProps();
    if (!this.props) return;
    await createRequestFavorite(this.props);
  };

  saveFavorite = async () => {
    this.createProps();
    if (!this.props) return;
    await saveRequestFavorite(this.props, this.favoriteItemId);
  };

  saveImageSetting = () => {
    const items: ImageSetting[] = [...this._item.value.imageSettings];
    let _item: ImageSetting | null = null;
    if (this._editImageSettingItem.value) {
      const index = this._item.value.imageSettings.indexOf(this._editImageSettingItem.value);
      _item = items[index];
      items.splice(index, 1);
    }
    if (!this._imageSetting.value.image || !this._imageSetting.value.displayStartDate.value) {
      throw '未入力があります';
    } else if (
      this._imageSetting.value.displayStartDate.value &&
      this._imageSetting.value.displayEndDate.value &&
      this._imageSetting.value.displayEndDate.value <= this._imageSetting.value.displayStartDate.value
    ) {
      throw '表示終了日時が表示開始日より前に設定されています';
    } else if (_item && _item.displayEndDate.value && !this._imageSetting.value.displayEndDate?.value) {
      throw '表示終了日時を入力してください';
    } else if (
      // 重複の確認
      items.some(
        (imageSetting) =>
          imageSetting.displayStartDate.value &&
          this._imageSetting.value.displayStartDate.value &&
          imageSetting.displayEndDate.value &&
          this._imageSetting.value.displayEndDate.value &&
          imageSetting.displayStartDate.value <= this._imageSetting.value.displayStartDate.value &&
          this._imageSetting.value.displayStartDate.value <= imageSetting.displayEndDate.value
      )
    ) {
      throw 'すでに設定されているデータと期間が重複しています。';
    } else if (
      // 表示終了日時が入っていた場合の重複確認
      this.imageSetting.displayEndDate.value &&
      items.some(
        (imageSetting) =>
          imageSetting.displayEndDate.value &&
          this._imageSetting.value.displayStartDate?.value &&
          this._imageSetting.value.displayEndDate.value &&
          this._imageSetting.value.displayStartDate.value <= imageSetting.displayEndDate.value &&
          imageSetting.displayEndDate.value <= this._imageSetting.value.displayEndDate.value
      )
    ) {
      throw 'すでに設定されているデータと期間が重複しています。';
    } else if (
      // 表示終了日時が入っていなかった場合の重複確認
      !this.imageSetting.displayEndDate.value &&
      items.some(
        (imageSetting) =>
          imageSetting.displayEndDate.value &&
          this._imageSetting.value.displayStartDate?.value &&
          this._imageSetting.value.displayStartDate.value <= imageSetting.displayEndDate.value
      )
    ) {
      throw 'すでに設定されているデータと期間が重複しています。';
    } else {
      if (this._editImageSettingItem.value) {
        const index = this._item.value.imageSettings.indexOf(this._editImageSettingItem.value);
        this._item.value.imageSettings[index].image = this._imageSetting.value.image;
        this._item.value.imageSettings[index].displayStartDate = this._imageSetting.value.displayStartDate;
        if (this._imageSetting.value.displayEndDate) {
          this._item.value.imageSettings[index].displayEndDate = this._imageSetting.value.displayEndDate;
        }
      } else {
        this._item.value.imageSettings.push(this._imageSetting.value);
        this.resetImageSetting();
      }
      if (this._item.value.imageSettings.length) {
        this._item.value.imageSettings.sort((a, b) =>
          a.displayStartDate.value && b.displayStartDate.value
            ? a.displayStartDate.value - b.displayStartDate.value
            : -1
        );
      }
      if (this._item.value.imageSettings.slice(-1)[0]) {
        this._isImageSettingLastEndDate.value = !!this._item.value.imageSettings.slice(-1)[0].displayEndDate.value;
      }
      this._isActiveDialog.value = false;
    }
  };

  resetImageSetting = () => {
    this._imageSetting.value = {
      image: '',
      displayStartDate: new DisplayDate(),
      displayEndDate: new DisplayDate(),
    };
    this._editImageSettingItem.value = null;
  };
}

export const useSettingFavorite = (favoriteItemStatusId?: string) => {
  const setting = new FavoriteSetting(favoriteItemStatusId);
  return setting;
};

export const useFavorites = () => {
  const favorites = new Favorites();
  return { favorites: reactive(favorites) };
};

export const useFavorite = (favoriteItemId: string) => {
  const favorite = new Favorite(favoriteItemId);
  return { favorite };
};
