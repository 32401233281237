import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';

export interface FavoriteItem {
  favoriteItemId: string;
  favoriteItemName: string;
  color: string;
  defaultImage: string;
  imageSettings?: {
    image: string;
    displayStartDate: number;
    displayEndDate?: number;
  }[];
  accessStartDate?: number;
  accessEndDate?: number;
  deleteDate?: number;
  createDate: number;
}

export interface FavoriteItemProps {
  favoriteItemName: string;
  color: string;
  defaultImage: string;
  imageSettings?: {
    image: string;
    displayStartDate: number;
    displayEndDate?: number;
  }[];
  accessStartDate?: number;
  accessEndDate?: number;
  deleteDate?: number;
}

export interface FavoriteStatus {
  favoriteItemStatusId: string;
  favoriteItemStatusName: string;
  intervalCount: number;
  textColor: string;
  nameBackGroundColor: string;
  image?: string;
  description?: string;
  linkUrl?: string;
}

export interface FavoriteStatusItem {
  settingId: 'favoriteItemStatus';
  value: {
    statuses: FavoriteStatus[];
  };
}

interface FavoriteStatusSettingProps {
  statuses: FavoriteStatus[];
}

export const getRequestFavorites = async (): Promise<FavoriteItem[]> => {
  if (myAttributes.myRequestPermissions?.favoriteItems) {
    const result = await core.httpClient.get('/admin/public/favoriteItems');
    return result.data as FavoriteItem[];
  } else return [] as FavoriteItem[];
};

export const getRequestFavorite = async (favoriteItemId: string): Promise<FavoriteItem> => {
  if (myAttributes.myRequestPermissions?.favoriteItems) {
    const result = await core.httpClient.get(`/admin/public/favoriteItems/${favoriteItemId}`);
    return result.data as FavoriteItem;
  } else return {} as FavoriteItem;
};

export const createFavoriteSetting = async (props: FavoriteStatusItem) => {
  await core.httpClient.post('/admin/public/settings', props);
};

export const getFavoriteSetting = async () => {
  const result = await core.httpClient.get('/admin/public/settings/favoriteItemStatus');
  return result.data as FavoriteStatusItem;
};

export const saveFavoriteSetting = async (props: FavoriteStatusSettingProps) => {
  await core.httpClient.put('/admin/public/settings/favoriteItemStatus', props);
};

export const createRequestFavorite = async (props: FavoriteItemProps) => {
  await core.httpClient.post('/admin/public/favoriteItems', props);
};

export const saveRequestFavorite = async (props: FavoriteItemProps, favoriteItemId: string) => {
  await core.httpClient.put(`/admin/public/favoriteItems/${favoriteItemId}`, props);
};
