






















































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { FavoriteStatus } from '@/admin/favorites';
import { useSettingFavorite } from '@/composition/favorites';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'FavoritesSettings',
  components: {
    FcRoleLoading,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('favorites'));
    const { confirmDialog } = useConfirm();

    const setting = useSettingFavorite();
    const notification = useNotification();
    const isSaving = ref(false);
    const headers = [
      { text: 'ステータス名', value: 'favoriteItemStatusName' },
      { text: '推し期間', value: 'intervalCount' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    const remove = async (item: FavoriteStatus) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      isSaving.value = true;
      try {
        await setting.removeFavoriteSetting(item);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };
    return {
      pageTitle: 'ランク設定',
      myRoleSettings,
      isSaving,
      setting,
      headers,
      tableHeight,
      remove,
    };
  },
});
